import React, { useEffect, useState } from 'react';
import VideoPopup from './VideoPopup';
import CardNba from './Card';
import axios from 'axios';
import { API_URL } from '../server';


const StartNba = () => {

    const server_backend = API_URL;
    const [isVideoPopupOpen, setIsVideoPopupOpen] = useState(false);
    const [links, setLinks] = useState("");
    const [fetchTeams, setFetchTeam] = useState([]);
    const [isViews, setIsviews] = useState(false);
    const [isViews2, setIsviews2] = useState(false);

    const [groupIn1, setGroupIn1] = useState("");
    const [groupIn2, setGroupIn2] = useState("");

    const openVideoPopup = () => {
        setIsVideoPopupOpen(true);
    };

    const closeVideoPopup = () => {
        setIsVideoPopupOpen(false);
    };
    const group1 = {}
    const group2 = {}
    const regex = /\.php/g;

    useEffect(() => {

        const fetchData = async () => {
            try {

                const response = await axios.post(`${server_backend}/teams-to-page`);
                setFetchTeam(response.data)
            } catch (error) {
                console.log('ha ocurrido un error inesperado');
            }
        }
        fetchData()
    }, []);

    //Funcion para que nos ayude imprimir el primer anuncio
    let clickCount = 0;
    function openWindowAndCount() {
        if (clickCount < 3) {
            window.open('https://distrustawhile.com/ggud85twbm?key=133a638e87cb26e98d72f4ab749c04af', '_blank');

            clickCount++;

            if (clickCount === 3) {
                setIsviews(true)
            }
        }
    }

    let clickCount2 = 0;
    function openWindowAndCount2() {
        if (clickCount2 < 3) {
            window.open('https://distrustawhile.com/fjf0ehidy?key=0af71abf97b19f7d6973f3d567b34769', '_blank');

            clickCount2++;

            if (clickCount2 === 3) {
                setIsviews2(true)
            }
        }
    }


    return (
        <div className="wrapper">

            {isVideoPopupOpen ? (<div style={{ zIndex: 1555 }}>
                <div className='col-xs-12'><h1 className='text-center'>{groupIn1} VS {groupIn2}</h1></div>
                <div style={{ paddingRight: "89%" }}>
                    <button className='btn btn-danger' onClick={() => {
                        setIsVideoPopupOpen(false)
                    }}>Volver hacia atras</button></div><br />
                <div className='row'>
                    <div className='col-xs-12 col-sm-9'>
                        {links.indexOf('.php') !== -1 ? (
                            <>
                                <iframe
                                    allow="encrypted-media"
                                    width="100%"
                                    height="100%"
                                    marginwidth="0"
                                    marginheight="0"
                                    scrolling="no"
                                    frameborder="0"
                                    allowfullscreen="yes"
                                    src={links} __idm_id__="1515521"
                                    style={{
                                        zIndex: isViews ? 0 : 1
                                    }}
                                ></iframe>
                                {!isViews && (
                                    <div
                                        className="overlay"
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: '#cecece54',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            zIndex: 1,
                                        }}
                                    >
                                        <a className='btn btn-light' onClick={() => {
                                            openWindowAndCount()
                                        }}>Haga click aqui 3 veces para ver bien</a>
                                    </div>
                                )}
                            </>
                        ) : (
                            isViews ? (
                                links.indexOf('m3u8') !== -1 ? (
                                    <VideoPopup isOpen={isVideoPopupOpen} handleClose={closeVideoPopup} links={links} />
                                ) : (<iframe
                                    allow="encrypted-media"
                                    width="100%"
                                    height="100%"
                                    marginwidth="0"
                                    marginheight="0"
                                    scrolling="no"
                                    frameborder="0"
                                    allowfullscreen="yes"
                                    src={links} __idm_id__="1515521"
                                    style={{
                                        zIndex: isViews ? 0 : 1
                                    }}
                                ></iframe>)
                            ) : (
                                <div
                                    className="overlay"
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: '#cecece54',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        zIndex: 1, // Overlay en la parte superior cuando isViews es false
                                    }}
                                >
                                    <a className='btn btn-light' onClick={() => {
                                        openWindowAndCount()
                                    }}>Haga click aqui 3 veces para ver bien</a>
                                </div>
                            )
                        )}
                    </div>

                    <div className='col-sm-3 col-xs-12 w-100 h-100'>
                        <iframe frameBorder="0" width="100%" height="600" id="cid0020000361007311776" allow="autoplay" src="https://adictonba.chatango.com/"></iframe>
                    </div>
                </div>

            </div>) : (
                <div className="container-fluid">
                    <h1 className='text-left'>NBA Live Streams Online</h1>
                    <div className='col-md-12'>
                        <div className='row'>
                            <div className='row' style={{ backgroundColor: "aliceblue", height: "2px", width: "100%" }}></div><br />
                            <div className='col-md-8'>
                                <div className="row">

                                    {fetchTeams.map((fetch_teams) => (

                                        <button
                                            className='btn btn-light border-0 zoomin'
                                            style={{ margin: "2px" }}
                                            onClick={() => {

                                                openVideoPopup();
                                                setLinks(`${fetch_teams.link}`);
                                                setGroupIn1(fetch_teams.team1_name);
                                                setGroupIn2(fetch_teams.team2_name);
                                                openWindowAndCount2()

                                            }}
                                        >
                                            <CardNba
                                                group1={{ name: fetch_teams.team1_name, img: process.env.PUBLIC_URL + fetch_teams.team1_img }}
                                                group2={{ name: fetch_teams.team2_name, img: fetch_teams.team2_img }}
                                                currentdate={fetch_teams.currentdate}
                                            />
                                        </button>
                                    ))}
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <p className='text-left'>Apreciamos tu interés en nuestra plataforma deportiva, donde nos esforzamos por brindarte la mejor experiencia de transmisión en línea. Entendemos que los anuncios pueden resultar molestos, pero son esenciales para mantener nuestros servidores en funcionamiento y ofrecerte un servicio de alta calidad.
                                    <br /> <br />
                                    Te invitamos a ser paciente con los anuncios, ya que permiten que podamos mantener nuestra plataforma en línea y seguir proporcionándote acceso a tus eventos deportivos favoritos. Ya sea que desees disfrutar de partidos en vivo o revisar contenido archivado, estamos aquí para satisfacer todas tus necesidades deportivas.
                                    <br /><br />
                                    En este momento, te animamos a no depender completamente de tu conexión de cable o televisor. Experimenta un nivel superior con nuestra plataforma de transmisión en línea. Simplemente menciona el evento deportivo que te interesa, y estaremos encantados de ayudarte a verlo en línea en el momento de la transmisión.
                                    <br /><br />
                                    Con nosotros, disfruta de la transmisión de la NBA sin depender de un cable, y descubrirás lo fácil que es hacerlo con internet. Si deseas ver cualquier partido de tu colegio o las finales de la NBA desde la comodidad de tu hogar, conéctate a nuestra plataforma a través de la televisión y disfruta de deportes en vivo desde los canales locales de tu área.
                                    <br />
                                    Agradecemos tu comprensión y paciencia, y esperamos seguir brindándote un servicio deportivo de calidad. ¡Gracias por ser parte de nuestra comunidad deportiva en línea!</p>
                            </div>
                        </div>
                    </div>
                </div>)}
            <br />
            <br />
        </div>

    );
};

export default StartNba;
