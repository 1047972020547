import React from 'react';
import EarthCanvas from './canvas/Ball';
import { logotv } from '../assets';

const Header = () => {
    return (
        <header id="topnav">
        <div className="navbar-custom" style={{ backgroundColor: "#3b434e" }}>
          <div className="container-fluid">
            <ul className="list-unstyled topnav-menu float-right mb-0">
              <li className="dropdown notification-list">
                <a className="navbar-toggle nav-link">
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </a>
              </li>
  
              <li className="dropdown notification-list">
                <div className="logo-box bouncing-ball" style={{ marginLeft: "53px", marginTop: "-0" }}></div>
              </li>
            </ul>
  
            <div className="fuenteadicto" style={{ position: "fixed" }}>
              <h1>
                <img src={logotv} alt="logotv" style={{ maxWidth: "65px", marginTop: "-9px" }} />
                <span style={{ display: "inline-block", marginLeft: "10px" }}>Urbano Deportes TV</span>
              </h1>
            </div>
  
          </div>
        </div>
      </header>
    );
};

export default Header;
