import React from "react";
import { nbalogo } from "../assets";



const CardNba = ({ group1, group2, currentdate }) => {


    return (
        
        <div className="col-md-12" style={{ padding: 0, top: "10px", maxWidth: "684px", minWidth: "684px" }}>
            <div className="card">
                <div className="row no-gutters">
                    <div className="col-md-2">
                        <div style={{padding: "8px", marginTop: "10px"}}>
                        <img className="card-img-top img-fluid" src={group1.img} style={{ width: "180px" }} />
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                            <h4 >{group1.name} VS {group2.name}</h4>
                            <h5 className="card-text">
                                {currentdate}
                            </h5>
                            <p>haga click aqui para ver...</p>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div style={{padding: "8px", marginTop: "10px"}}>
                        <img className="card-img-top img-fluid" src={group2.img} style={{ width: "180px" }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default CardNba;