import React, { useEffect } from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';

const VideoPopup = ({ isOpen, handleClose, links }) => {
  //const videoId = 'my-video';
  

  return (
    <div>
      <ReactPlayer
        url={links}
        controls={true}
        width="100%"
        height="100%"
      />
    </div>

  );
};

export default VideoPopup;
