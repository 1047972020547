import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            Urbano Deportes does not host any of the live stream videos playing on this site. All videos are hosted on external streaming site that are freely available online. please contact video hosting provider or media poster for takedown or any dmca complaints
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
